import { ssrMenuItems } from '@/common/hooks/useMenuItems';
import { getServerSidePropsPrefetch } from '@/common/utils/ssr';
import HeaderBar from '@/common/layouts/HeaderBar';
import DefaultLayout from '@/common/layouts/DefaultLayout';
import { Box, Grid, Typography } from '@mui/material';
import Division from '@/common/components/Division';
import Footer from '@/common/layouts/Footer';

export default function ShortCodePage() {
  return (
    <>
      <HeaderBar />
      <DefaultLayout>
        <Grid container direction="column">
          <Box display="flex" alignItems="center" marginBottom={2}>
            <Typography variant="h6">Page Not Found</Typography>
          </Box>

          <Division />
        </Grid>
      </DefaultLayout>
      <Footer />
    </>
  );
}

export const getServerSideProps = getServerSidePropsPrefetch([ssrMenuItems], false, true);
